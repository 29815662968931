import clsx from 'clsx';
import GoogleIcon from 'components/common/svg/GoogleIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface GoogleLoginBtnProps {
  className?: string;
  onClick: () => void;
  name: string;
}

function GoogleLoginBtn({ className, onClick, name }: GoogleLoginBtnProps) {
  return (
    <button
      type="button"
      className={clsx('social bg-transparent p-0', className)}
      onClick={onClick}
    >
      <div className="go text-[14px]">
        <GoogleIcon />
        {name}
      </div>
    </button>
  );
}

export default GoogleLoginBtn;
