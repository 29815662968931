/* eslint-disable react/no-danger */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AnnouncementLikeButton from '@/components/Buttons/AnnouncementLikeButton';
import Animation from '@/components/common/Animation';
import Button from '@/components/common/Button';
import Skeleton from '@/components/common/Skeleton';
import Header from '@/components/Header';
import { cn } from '@/lib/utils';
import { RootState } from '@/shared/rootReducers';
import {
  useGetAnnouncementsQuery,
  useGetAnnouncementByUserQuery,
  useLikeAnnouncementMutation,
} from '@/shared/slices/announcementSlice';

interface Event {
  id: string;
  title_en: string;
  title_fr: string;
  content_en: string;
  content_fr: string;
  created_at: string;
  likeCount: number;
  announcementLiked?: boolean;
}

function AnnouncementPage() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { user } = useSelector((state: RootState) => state?.auth);
  const { data: shortEvents, isFetching: shortEventsFetching } =
    useGetAnnouncementsQuery({ limit: 10 }, { skip: user });
  const { data: fullEvents, isFetching: fullEventsFetching } =
    useGetAnnouncementByUserQuery({ limit: 10 }, { skip: !user });
  const [likeAnnouncement, { isLoading: handleLikeLoading }] =
    useLikeAnnouncementMutation();
  const currentAnnouncementData = user ? fullEvents : shortEvents;

  const { data: announcements }: any = currentAnnouncementData ?? {};
  const handleLike = async ({
    announcementId,
    announcementLiked,
  }: {
    announcementId: number;
    announcementLiked: boolean;
  }) => {
    if (announcementLiked || !announcementId) return;
    try {
      await likeAnnouncement({
        id: announcementId,
      });
    } catch (error) {
      console.error('Error liking announcement');
    }
  };
  return (
    <>
      <div className="absolute w-full h-[1050px] pointer-events-none bg-forgot-gradient-background" />
      <Header />
      <div className="flex text-white font-bold justify-center mt-6">
        <h1>{t('common.announcement').toUpperCase()}</h1>
      </div>
      <div>
        <div className="max-w-[800px] mx-auto text-white">
          {shortEventsFetching ? (
            <div className="space-y-3 mb-4">
              <div className="space-y-2">
                <Skeleton className="w-1/4 h-4 opacity-10" />
                <Skeleton className="w-full h-6 opacity-10" />
                <Skeleton className="w-4/5 h-4 opacity-10" />
              </div>
              <div className="space-y-2">
                <Skeleton className="w-1/4 h-4 opacity-10" />
                <Skeleton className="w-full h-6 opacity-10" />
                <Skeleton className="w-4/5 h-4 opacity-10" />
              </div>
            </div>
          ) : (
            <Animation>
              <ul className="relative border-0 border-l border-solid border-white/10 list-none">
                {announcements?.map((announcement: Event) => (
                  <li className="mb-10 ms-4">
                    <div className="border-none bg-transparent text-start">
                      <div className="absolute w-3 h-3 bg-gray-500 rounded-full mt-1.5 -start-1.5 border border-white" />
                      <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                        {announcement.created_at}
                      </time>
                      <h3 className="text-lg font-semibold text-white">
                        {language === 'fr'
                          ? announcement.title_fr
                          : announcement.title_en}
                      </h3>
                      <div
                        className={cn(
                          'mb-4 text-base font-normal text-white/90 dark:text-gray-400'
                        )}
                        dangerouslySetInnerHTML={{
                          __html:
                            language === 'fr'
                              ? announcement.content_fr
                              : announcement.content_en,
                        }}
                      />
                      <div className="flex justify-end">
                        <AnnouncementLikeButton
                          text={announcement.likeCount}
                          onClick={() =>
                            handleLike({
                              announcementId: Number(announcement.id),
                              announcementLiked:
                                !!announcement.announcementLiked,
                            })
                          }
                          liked={!!announcement?.announcementLiked}
                          className="h-full text-base border-none rounded-sm"
                          disable={
                            !!handleLikeLoading ||
                            !!fullEventsFetching ||
                            !!announcement?.announcementLiked
                          }
                        />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </Animation>
          )}
        </div>
      </div>
    </>
  );
}

export default AnnouncementPage;
