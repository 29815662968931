import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoCloseCircleSharp } from 'react-icons/io5';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/common/Button';
import { setProductsState } from '@/shared/reducers/productsReducer';
import { RootState } from '@/shared/rootReducers';

function NeedUpgradePlanModal() {
  const { showPermissionEditModal } = useSelector(
    (state: RootState) => state?.products
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Modal
      className="modal-container"
      isOpen={!!showPermissionEditModal}
      overlayClassName="modal-overlay"
    >
      <div className="max-w-md bg-white rounded-lg md:min-w-full p-7 pt-2 pe-2 ">
        <div className="flex justify-end mb-1">
          <Button
            text=""
            className="bg-transparent border-0 outline-none !justify-end"
            icon={<IoCloseCircleSharp className="text-3xl" />}
            onClick={() =>
              dispatch(setProductsState({ showPermissionEditModal: false }))
            }
          />
        </div>

        <div>{t('shopify.notAbleToEdit')}</div>
        <div className="flex justify-center mt-4">
          <Button
            className="p-2 px-8 mr-2 text-white border-none rounded-lg bg-primary hover:bg-purple-600"
            text={t('shopify.letUpgrade')}
            type="button"
            onClick={() => {
              navigate('/upgrade');
              dispatch(setProductsState({ showPermissionEditModal: false }));
            }}
          />
          <Button
            className="p-2 px-8 mr-2 text-white border-none rounded-lg bg-gray-300 hover:bg-gray-400"
            text={t('common.cancel')}
            type="button"
            onClick={() =>
              dispatch(setProductsState({ showPermissionEditModal: false }))
            }
          />
        </div>
      </div>
    </Modal>
  );
}

export default NeedUpgradePlanModal;
