import { WarningRounded } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../common/Button';

import { setProductsState } from '@/shared/reducers/productsReducer';
import { RootState } from '@/shared/rootReducers';

function ProductErrorDisplay() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { products, currentProductId } = useSelector(
    (state: RootState) => state.products
  );
  const product = products?.find((prod) => prod.id === currentProductId);
  return (
    <div>
      <div className="flex flex-col items-center text-white justify-center">
        <div className="flex">
          <WarningRounded className="!text-6xl text-amber-400" />
          <div className="inline-flex flex-col ml-2">
            {/* Regenerate Failed Product */}
            <h3 className="m-0 mb-2">
              {t('memberArea.regenerateProductTitle')}
            </h3>
            <span className="text-sm">
              {t('memberArea.regenerateProductFailed')}
            </span>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <Button
            className="p-4 mr-2 text-white border-none rounded-lg bg-primary"
            text={t('memberArea.regenerate')}
            onClick={() => {
              dispatch(
                setProductsState({
                  regenerateProduct: product,
                })
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ProductErrorDisplay;
