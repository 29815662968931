/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCloseCircleSharp } from 'react-icons/io5';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import Button from '../common/Button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../common/Card';

import { setProductsState } from '@/shared/reducers/productsReducer';
import { RootState } from '@/shared/reduxStore';

function ShopifyMessageModal() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { shopifyMessageModal } = useSelector(
    (state: RootState) => state?.products
  );
  const [message, setMessage] = useState('');

  useEffect(() => {
    const shopify = searchParams.get('shopify');
    if (!shopify) return;
    setMessage(t(`shopify.${shopify}`));
    dispatch(setProductsState({ shopifyMessageModal: true }));
  }, [searchParams]);

  return (
    <Modal
      closeTimeoutMS={500}
      className="modal-container relative max-w-350 outline-none"
      isOpen={shopifyMessageModal ?? false}
      overlayClassName="modal-overlay"
    >
      <Button
        text=""
        className="bg-transparent border-0 outline-none w-full !justify-end absolute top-3"
        icon={<IoCloseCircleSharp className="text-3xl" />}
        onClick={() =>
          dispatch(setProductsState({ shopifyMessageModal: false }))
        }
      />
      <Card>
        <CardHeader>
          <CardTitle className="m-0">{t('shopify.shopifyConnect')}</CardTitle>
          <CardDescription className="text-xs">
            {t('shopify.shopifyConnectSubTitle')}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid w-full items-center gap-4">
            <div className="mt-3 leading-6 text-sm text-center flex flex-col">
              <p
                dangerouslySetInnerHTML={{
                  __html: message,
                }}
              />
            </div>
          </div>
        </CardContent>
        <CardFooter className="flex justify-end mt-3">
          <Button
            onClick={() =>
              dispatch(setProductsState({ shopifyMessageModal: false }))
            }
            className="border-none bg-zinc-200 px-4 text-sm rounded-sm py-2 mr-2 transition-all"
          >
            {t('common.close')}
          </Button>
        </CardFooter>
      </Card>
    </Modal>
  );
}

export default ShopifyMessageModal;
