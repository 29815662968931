import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { ProductListResponseProps } from '../slices/productSlice';

interface ProductsState extends ProductListResponseProps {
  page?: number;
  limit?: number;
  currentProductId?: number;
  hasMore?: boolean;
  productsReset?: boolean;
  productsRegenerate?: boolean;
  titleRegenerate?: string;
  regenerateProduct?: any;
  allErrorProducts?: boolean;
  selectedService?: string | null;
  notAbleToEdit?: boolean;
  showPermissionEditModal?: boolean;
  currentTab?: string;
  shopifyTitleRegenerate?: string;
  shopifyConnectModal?: boolean;
  shopifyMessageModal?: boolean;
  shopifyImport?: boolean;
  shopifyImportBasic?: boolean;
  shopifyImportTemplate?: boolean;
  shopifyHomepageImportTemplate?: boolean;
}

const initialState: ProductsState = {
  page: 1,
  limit: 6,
  currentProductId: 0,
  hasMore: false,
  generatedProducts: 0,
  totalProducts: 0,
  regeneratedProducts: 0,
  stock: 0,
  products: [],
  regenerateProduct: null,
  allErrorProducts: false,
  selectedService: null,
  productsReset: false,
  productsRegenerate: false,
  titleRegenerate: '',
  notAbleToEdit: false,
  showPermissionEditModal: false,
  currentTab: 'products',
  shopifyTitleRegenerate: '',
  shopifyConnectModal: false,
  shopifyMessageModal: false,
  shopifyImport: false,
  shopifyImportBasic: false,
  shopifyImportTemplate: false,
  shopifyHomepageImportTemplate: false,
};

const productsStateSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProductsState: (state, { payload }: PayloadAction<ProductsState>) => ({
      ...state,
      ...payload,
    }),
    resetProductsState: () => initialState,
  },
});

export const { setProductsState, resetProductsState } =
  productsStateSlice.actions;

export default productsStateSlice.reducer;
