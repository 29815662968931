import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Admin from './Admins';
import ContentV3 from './Content';
import ProductHeader from './Header';
import Reviews from './Review';
import SectionDetails from './SectionDetails';

import {
  servicesMap,
  Service,
  servicesMapV3,
  ServiceV3,
} from '../../../shared/configs/servicesMap';
import ProductAppLayout from '../Layout';
import LoadingSkeleton from '../LoadingSkeleton';
import ProductErrorDisplay from '../ProductErrorDisplay';

import Announcement from '@/components/Announcement';
import TextLoadingState from '@/components/common/TextLoadingState';
import { setProductsState } from '@/shared/reducers/productsReducer';
import { RootState } from '@/shared/reduxStore';
import {
  useGetProductDetailQuery,
  useGetProductListQuery,
} from '@/shared/slices/productSlice';

export const variableFlouti = 8;

interface ServicesProps {
  productServiceDetail: any;
  productDetail: any;
  user: any;
  reviews: any[];
}
function Services({
  productServiceDetail,
  productDetail,
  user,
  reviews,
}: ServicesProps) {
  const {
    products,
    currentProductId,
    limit,
    allErrorProducts,
    selectedService,
  } = useSelector((state: RootState) => state?.products);
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');

  const { isFetching } = useGetProductDetailQuery(
    {
      productId: currentProductId,
    },
    {
      skip: currentProductId === 0,
    }
  );
  const { isSuccess: isProductsLoaded } = useGetProductListQuery({
    page: 1,
    limit,
    reset: true,
  });

  useEffect(() => {
    if (products && products.length <= 0)
      dispatch(setProductsState({ selectedService: null }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const selectedServiceData: Service | undefined = servicesMap.find(
    (service) => service.key === selectedService
  );

  const selectedServiceDataV3: ServiceV3 | undefined = servicesMapV3.find(
    (service) => service?.serviceKey === `generate_${selectedService}`
  );

  const serviceContentV3 =
    productServiceDetail?.[selectedServiceDataV3?.serviceKey ?? ''] ??
    undefined;

  const productsData = products ?? [];
  const isProductLoading = !isProductsLoaded && productsData?.length <= 0;
  const isNoProducts = isProductsLoaded && productsData?.length <= 0;

  const loadingCheckWithExistProducts =
    isProductLoading || (!productDetail && isFetching);

  const loadingCheckWhileGeneratingProduct =
    productsData?.length > 0 && !productServiceDetail;

  const emptyDescriptionSelected =
    selectedService === 'description' && !productDetail?.description;
  return (
    <ProductAppLayout>
      <>
        {/* Why we have this complex logic for such a simple loading state?
            - In here, we have to cover two loading states. The first one is for product list and
              the last one is the service detail content.
            - The logic basically work as follow:
              - Check if the product list is loading -> show loading state.
              - If products fetched but no product -> Show the placeholder.
              - If products fetched and have the products data but the content is not there -> show loading state.
              - There is no such case have the products data but the content is undefined.
        */}
        {(loadingCheckWithExistProducts ||
          loadingCheckWhileGeneratingProduct) && <LoadingSkeleton />}
        {isNoProducts && (
          <div>
            <Announcement />
            <div className="product-container">
              <div className="flex justify-between items-center space-x-4 opacity-20 product-text">
                <div className="product-name-head w-150 md:w-200 xl:w-350 space-y-2 uppercase">
                  {t('memberArea.yourProductName')}
                </div>
                <div className="h-44 w-44 bg-white bg-opacity-30 rounded-lg" />
              </div>
            </div>
            <div className="max-w-[800px] space-y-4 opacity-20 w-250 md:w-350 xl:w-800">
              {t('memberArea.productContentPlaceholder')}
            </div>
          </div>
        )}

        {productDetail &&
          productServiceDetail &&
          products &&
          products.length > 0 && (
            <>
              <ProductHeader {...productServiceDetail} />
              {emptyDescriptionSelected && !allErrorProducts && (
                <TextLoadingState />
              )}
              {emptyDescriptionSelected && allErrorProducts && (
                <ProductErrorDisplay />
              )}
              {selectedServiceDataV3 && serviceContentV3 && (
                <ContentV3
                  {...selectedServiceDataV3}
                  serviceData={serviceContentV3 ?? []}
                  userPlan={user.plan}
                />
              )}
              {selectedService !== 'reviews' &&
                selectedServiceData &&
                !serviceContentV3 && (
                  <SectionDetails
                    {...selectedServiceData}
                    serviceKey={selectedServiceData.key}
                    userPlan={user.plan}
                    productServiceDetail={productServiceDetail}
                  />
                )}
              {selectedService === 'reviews' && (
                <Reviews
                  reviews={reviews}
                  productServiceDetail={productServiceDetail}
                />
              )}
              {selectedService === 'admin' && <Admin />}
              {selectedService === 'seo' && (
                <div>
                  <h1>SEO</h1>
                </div>
              )}
            </>
          )}
      </>
    </ProductAppLayout>
  );
}

export default Services;
