/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import Button from 'components/common/Button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Image from '../ImportComponent/Image';

import GetStoreName1Mobile from '@/assets/shopify/get-shopify-store-name-1-mobile.png';
import GetStoreName1 from '@/assets/shopify/get-shopify-store-name-1.png';
import GetStoreName2Mobile from '@/assets/shopify/get-shopify-store-name-2-mobile.png';
import GetStoreName2 from '@/assets/shopify/get-shopify-store-name-2.png';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/common/Card';
import { Input } from '@/components/common/Input';
import { Label } from '@/components/common/Label';

function ShopifyConnectSimple() {
  const { t } = useTranslation();
  const [shopName, setShopName] = useState<string | null>(null);
  const [zoomIn, setZoomin] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (imageSrc: any) => {
    setSelectedImage(imageSrc);
    setZoomin(true);
  };

  const closeModal = () => {
    setZoomin(false);
    setSelectedImage(null);
  };
  return (
    <Card>
      <CardHeader>
        <CardTitle className="m-0 w-9/12 leading-6">
          {t('shopify.connectYourShopify')}
        </CardTitle>
        <CardDescription className="text-sm">
          {t('shopify.connectYourShopifySubTitle')}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Label htmlFor="name">{t('shopify.enterYourShopifyAddress')}</Label>
        <Label
          className="font-normal text-base w-full pt-3"
          htmlFor="shopify-name"
        >
          <Input
            className="p-0 m-0 outline-none text-sm w-8/12 border-defaultBlackLight/60 bg-white bg-opacity-10 empty:before:italic empty:before:text-zinc-500 text-black indent-2 placeholder:text-zinc-500"
            onBlur={(event) => {
              const { value } = event.currentTarget;
              setShopName(value ?? null);
            }}
            placeholder={t('shopify.enterYourShopifyAddressPlaceholder')}
          />
          <span
            className="mt-1 text-xs"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('shopify.shopifyConnectInstructions'),
            }}
          />
          <div className="mt-3">
            <div className="font-bold text-md">
              {t('shopify.getshopifyAddressHelp')}
            </div>
            <div className="hidden lg:flex">
              <Image imageSrc={GetStoreName1} zoomIn={openModal} />
              <Image imageSrc={GetStoreName2} zoomIn={openModal} />
            </div>
            <div className="lg:hidden">
              <Image
                className="!w-[100px]"
                imageSrc={GetStoreName1Mobile}
                zoomIn={openModal}
              />
              <Image
                className="!w-[100px]"
                imageSrc={GetStoreName2Mobile}
                zoomIn={openModal}
              />
            </div>

            {zoomIn && (
              <div
                role="button"
                tabIndex={0}
                onClick={closeModal}
                className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
              >
                <div className="relative max-w-[1200px] max-h-[1200px]">
                  <Button
                    onClick={closeModal}
                    className="absolute top-0 right-0 text-white bg-black border-none rounded-full text-2xl"
                  >
                    &times;
                  </Button>
                  {selectedImage && (
                    <img
                      className="w-full h-auto max-w-[400px] max-h-[40vh] md:max-w-[1200px] md:max-h-[90vh] object-contain"
                      alt="Large view"
                      src={selectedImage}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </Label>
      </CardContent>
      <CardFooter className="flex justify-end mt-5">
        <Button
          onClick={async () => {
            if (!shopName) return;
            window.location.href = `${process.env.VITE_APP_API_URL}/shopify/auth?shop=${shopName}&token=${localStorage.getItem('productly')}`;
          }}
          className="border-none bg-primary px-4 text-sm rounded-sm py-2 hover:bg-shopify text-white transition-all"
        >
          {t('common.connect')}
        </Button>
      </CardFooter>
    </Card>
  );
}

export default ShopifyConnectSimple;
