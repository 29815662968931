/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { WarningRounded } from '@material-ui/icons';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import Button from 'components/common/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LuLoader2 } from 'react-icons/lu';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { Alert, AlertDescription } from '../common/Alert';
import { Card, CardContent, CardHeader, CardTitle } from '../common/Card';
import InputLabel from '../common/InputLabel';
import { Label } from '../common/Label';
import SelectLabel from '../common/SelectLabel';
import { Input } from '../common/shadcn-ui/Input';
import AliexpressLogo from '../common/svg/AliexpressLogo';
import AmazonLogo from '../common/svg/AmazonLogo';

import { setProductsState } from '@/shared/reducers/productsReducer';
import { RootState } from '@/shared/rootReducers';

interface AddProductModalProps {
  showAddProductForm: boolean;
  isSubmitting: boolean;
  isGenerating: boolean;
  setShowAddProductForm: React.Dispatch<any>;
  setNewProduct: React.Dispatch<any>;
  handleAddProduct: (e: any) => Promise<void>;
  newProduct: Record<string, any>;
  errorMessage: string;
  formSubmitted: boolean;
  setFormSubmitted: React.Dispatch<any>;
  scrappingData: Record<string, any>;
  handleRetryGenerate: () => void;
}

Modal.setAppElement('#root');

const languages = [
  {
    language: 'English (United States, UK, Australia, Canada)',
    value: 'english',
  },
  {
    language: 'Chinese (China)',
    value: 'chinese',
  },
  {
    language: 'Japanese (Japan)',
    value: 'japanese',
  },
  {
    language: 'German (Germany)',
    value: 'german',
  },
  {
    language: 'French (France)',
    value: 'french',
  },
  {
    language: 'Korean (South Korea)',
    value: 'korean',
  },
  {
    language: 'Dutch (Netherlands)',
    value: 'dutch',
  },
  {
    language: 'Portuguese (Brazil)',
    value: 'portuguese',
  },
  {
    language: 'Russian (Russia)',
    value: 'russian',
  },
  {
    language: 'Italian (Italy)',
    value: 'italian',
  },
  {
    language: 'Espanol (Spanish)',
    value: 'spanish spain',
  },
  {
    language: 'Espanol (America Latina)',
    value: 'america latina spanish',
  },
];

export function AddProductModal({
  showAddProductForm,
  isSubmitting = false,
  isGenerating = false,
  setShowAddProductForm,
  setNewProduct,
  handleAddProduct,
  newProduct,
  errorMessage,
  formSubmitted,
  setFormSubmitted,
  scrappingData,
  handleRetryGenerate,
}: AddProductModalProps) {
  const { t } = useTranslation('translation');
  const { regenerateProduct } = useSelector(
    (state: RootState) => state.products
  );
  const dispatch = useDispatch();
  return (
    <>
      <Modal
        className="modal-container"
        isOpen={isSubmitting}
        overlayClassName="modal-overlay"
      >
        <div className="max-w-md bg-white rounded-lg md:min-w-full p-7">
          <div className="animate-pulse">
            <div className="flex text-primary">
              <LuLoader2 className="animate-spin text-lg mt-[2px] mr-2" />
              {/* Adding product... */}
              <h3 className="m-0 mb-2 text-primary">
                {!isGenerating
                  ? t('memberArea.addingProduct')
                  : t('memberArea.regeneratingProduct')}
              </h3>
            </div>
            <div className="text-sm text-zinc-950">
              {/* Checking your product, this might take a while, thank you for your patien! */}
              {t('memberArea.verifyProduct')}
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal-container"
        isOpen={formSubmitted}
        overlayClassName="modal-overlay"
        onRequestClose={() => setFormSubmitted(false)}
      >
        <button
          type="button"
          className="modal-close-button"
          onClick={() => setFormSubmitted(false)}
        >
          &times;
        </button>
        <div className="relative flex items-center justify-center max-w-md bg-white rounded-lg md:min-w-full p-7">
          {scrappingData?.success ? (
            <div className="flex-col">
              <h2>
                {!scrappingData?.regenerateProductFailed
                  ? t('memberArea.productAddedSuccessTitle')
                  : t('memberArea.productAddedAgainSuccessTitle')}
              </h2>
              <p>
                {!scrappingData?.regenerateProductFailed
                  ? t('memberArea.productAddedAgainSuccessContent')
                  : t('memberArea.productAddedSuccessContent')}
              </p>
            </div>
          ) : (
            <div className="flex items-center">
              <WarningRounded className="!text-6xl text-amber-400" />
              <div className="inline-flex flex-col ml-2">
                {/* Add product failed */}
                <h3 className="m-0 mb-2">
                  {scrappingData?.regenerateProductFailed
                    ? t('memberArea.regeneratedFailed')
                    : t('memberArea.generatedFailed')}
                </h3>
                <div className="text-sm text-zinc-950">
                  {scrappingData?.regenerateProductFailed ? (
                    t('memberArea.regeneratedFailedTryAgain')
                  ) : (
                    <p>
                      {!scrappingData?.previous_failed
                        ? t('memberArea.generatedFailedTryAgain')
                        : t('memberArea.failedProduct')}
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        className="modal-container outline-0"
        isOpen={!!regenerateProduct}
        overlayClassName="modal-overlay"
      >
        <div className="max-w-md bg-white rounded-lg md:min-w-full p-7">
          <div className="flex">
            <WarningRounded className="!text-6xl text-amber-400" />
            <div className="inline-flex flex-col ml-2">
              {/* Regenerate Failed Product */}
              <h3 className="m-0 mb-2">
                {t('memberArea.regenerateProductTitle')}
              </h3>
              <span className="text-sm text-zinc-950">
                {/* After you confirm to regenerate this product, all the duplicate
                error products will be remove, are you sure? */}
                {t('memberArea.regenerateProductContent')}
              </span>
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <Button
              className="p-2 mr-2 text-white border-none rounded-lg bg-primary"
              text={t('common.confirm')}
              onClick={handleRetryGenerate}
            />
            <Button
              className="p-2 border-none rounded-lg bg-slate-100"
              text={t('common.cancel')}
              onClick={() =>
                dispatch(setProductsState({ regenerateProduct: null }))
              }
            />
          </div>
        </div>
      </Modal>
      <Modal
        className="modal-container"
        isOpen={showAddProductForm && !isSubmitting}
        overlayClassName="modal-overlay"
        onRequestClose={() => setShowAddProductForm(false)}
      >
        <Card className="w-full md:w-[400px] p-3 mx-10 transition duration-150 ease-in-out">
          <CardHeader className="flex flex-row justify-between items-center py-3">
            <CardTitle className="text-2xl font-bold">
              {t('memberArea.addProduct')}
            </CardTitle>
            <button
              className="bg-transparent border-none text-3xl !mt-0 cursor-pointer h-fit"
              type="button"
              onClick={() => setShowAddProductForm(false)}
            >
              &times;
            </button>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleAddProduct}>
              <InputLabel
                className="mt-1 mb-3"
                name={t('memberArea.productName')}
                placeholder={t('memberArea.productName')}
                onChange={(e) =>
                  setNewProduct({ ...newProduct, name: e.target.value })
                }
                value={newProduct.name}
              />
              <div>
                <Label className="font-bold text-base" htmlFor="productUrl">
                  {t('memberArea.productPage')}
                </Label>
                <div className="flex justify-end">
                  <AmazonLogo
                    className="mr-3"
                    width="5rem"
                    height="2rem"
                    textFill="#221f1f"
                  />
                  <AliexpressLogo width="5rem" height="1.5rem" />
                </div>
                <Input
                  id="productUrl"
                  name="productUrl"
                  className="mt-1 mb-3 w-[-webkit-fill-available] border-solid"
                  placeholder={t('common.productUrlPlaceholder')}
                  value={newProduct.amazonUrl}
                  onChange={(e) =>
                    setNewProduct({
                      ...newProduct,
                      amazonUrl: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <SelectLabel
                name={t('memberArea.outputLanguage')}
                inputData={languages}
                onValueChange={(value: string) =>
                  setNewProduct({ ...newProduct, lang: value })
                }
                placeholder={t('memberArea.outputLanguagePlaceHolder')}
              />
              {errorMessage && (
                <Alert variant="destructive" className="mt-4">
                  <ExclamationTriangleIcon className="w-4 h-4" />
                  <AlertDescription>
                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                  </AlertDescription>
                </Alert>
              )}
              <button
                type="submit"
                disabled={isSubmitting}
                className="mt-4 px-4 w-full py-3 rounded border-none bg-gradient-to-b from-[#8628C0] to-[#A238D5] hover:bg-gradient-to-b hover:from-[#8a24c9] hover:to-[#9431c2] text-white cursor-pointer font-bold"
              >
                {t('memberArea.addButton')}
              </button>
            </form>
          </CardContent>
        </Card>
      </Modal>
    </>
  );
}

export default AddProductModal;
