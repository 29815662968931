import './components/css/App.css';

import Forgot from 'pages/auth/Forgot';
import Login from 'pages/auth/Login';
import Register from 'pages/auth/Register';
import Verify from 'pages/auth/Verify';
import ChangePasswordPage from 'pages/protected/ChangePassword';
import Upgrade from 'pages/Upgrade';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import AccountArea from './components/app/account';
import Footer from './components/footer';
import MaintenancePage from './components/MaintenancePage';
import PrivateRoute from './components/tech/PrivateRoute';
import ConditionsVentes from './conditions-ventes';
import LegalMentions from './legal-mentions';
import MaintenanceToggle from './MaintenaceToggle';
import NotFound from './NotFound';
import Announcement from './pages/Announcement';
import Home from './pages/Home';
import MemberArea from './pages/protected/App'; // Make sure to import your MemberArea component
import Cancellation from './pages/protected/Cancellation';
import ShopifyConnectPage from './pages/Shopify';
import PrivacyPolicy from './politique-conf';
import countryUsingEUR, { currencySupport } from './shared/currency';
import { getCredential } from './shared/reducers/authReducer';
import { setPlanState } from './shared/reducers/planReducer';
import { RootState } from './shared/rootReducers';
import { useGetLanguageQuery } from './shared/slices/languageSlice';
import { useLazyGetUserCurrencyQuery } from './shared/slices/subscriptionSlice';
import { useGetSpecialTimeQuery } from './shared/slices/timeSlice';
import { productionLog } from './utils/helpers';

function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false); // Start with true for maintenance
  const { data: languageDynamic, isLoading: gettingLanguage } =
    useGetLanguageQuery();
  const toggleMaintenanceMode = () => {
    setIsMaintenanceMode(!isMaintenanceMode);
  };
  const [getCurrency, { currentData: currencyData }] =
    useLazyGetUserCurrencyQuery();
  const { data: specialTimeData } = useGetSpecialTimeQuery();

  useEffect(() => {
    dispatch(getCredential());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!specialTimeData) return;
    dispatch(setPlanState({ specialDay: !!specialTimeData?.specialtime }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialTimeData]);

  useEffect(() => {
    if (!user?.id) return;
    getCurrency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!languageDynamic?.country || gettingLanguage) return;

    const preferCurrency = currencyData?.currency;
    const isEuro = countryUsingEUR.includes(languageDynamic.country);
    const localCurrency = currencySupport?.[languageDynamic.country];

    productionLog(`languageDynamic ${JSON.stringify(languageDynamic)}`);
    productionLog(`localCurrency ${localCurrency}`);
    let currencyByCountry = 'usd';
    if (isEuro) {
      currencyByCountry = 'eur';
    }
    if (localCurrency) {
      currencyByCountry = localCurrency;
    }

    productionLog(`isEuro ${isEuro}`);
    productionLog(`currencyByCountry ${currencyByCountry}`);

    dispatch(setPlanState({ currency: preferCurrency ?? currencyByCountry }));

    if (localStorage.getItem('language_prefer')) return;
    const frenchCountries = ['ch', 'be', 'fr'].includes(
      languageDynamic?.country ?? 'us'
    );
    i18n.changeLanguage(frenchCountries ? 'fr' : 'en');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageDynamic, currencyData, gettingLanguage, i18n]);

  if (gettingLanguage && !languageDynamic) {
    return <p />;
  }

  const renderContent = () => {
    if (isMaintenanceMode) {
      return (
        <Routes>
          <Route
            path="/maintenance-toggle"
            element={<MaintenanceToggle onToggle={toggleMaintenanceMode} />}
          />
          <Route path="*" element={<MaintenancePage />} />
        </Routes>
      );
    }
    // Regular app routes
    return (
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/legal-mentions" element={<LegalMentions />} />
          <Route path="/upgrade" element={<Upgrade />} />
          <Route
            path="/conditions-generales-de-ventes"
            element={<ConditionsVentes />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/shopify" element={<ShopifyConnectPage />} />
          <Route path="/latest-release" element={<Announcement />} />
          <Route
            path="/app"
            element={
              <PrivateRoute>
                <MemberArea />
              </PrivateRoute>
            }
          />
          <Route
            path="/account"
            element={
              <PrivateRoute>
                <AccountArea />
              </PrivateRoute>
            }
          />
          <Route
            path="/subscription/cancel"
            element={
              <PrivateRoute>
                <Cancellation />
              </PrivateRoute>
            }
          />
          <Route
            path="/change-password"
            element={
              <PrivateRoute>
                <ChangePasswordPage />
              </PrivateRoute>
            }
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    );
  };
  return <Router>{renderContent()}</Router>;
}

export default App;
