import React from 'react';
import './css/featuresection.css';
import { useTranslation } from 'react-i18next';

import Animation from './common/Animation';
import SlideProducts from './tech/SlideProducts';

import { useGetMocksInformationQuery } from '@/shared/slices/homePageSlice';

function FeatureSection() {
  const { data: mockProducts, isLoading: isProductsLoading } =
    useGetMocksInformationQuery();
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', { keyPrefix: 'home' });

  return (
    <Animation className="flex flex-col items-center justify-center md:p-[70px_40px_90px_40px] xs:p-[70px_0px_90px_0px]">
      <>
        <div className="flex justify-center">
          <div className="feature-title-head slide-in-from-left">
            <h1 className="text-center leading-[55px] m-0 text-[40px] font-semibold text-white xs:text-[30px] xs:leading-[30px] sm:text-[40px] sm:leading-[40px]">
              {t('featureTitle1')}{' '}
              <span
                className={`${language === 'fr' && 'text-[#a238d5]'} inline-block relative`}
              >
                {language === 'fr' ? t('featureTitle2') : t('featureTitle3')}
              </span>{' '}
              <span
                className={`${language === 'en' && 'text-[#a238d5]'} inline-block relative`}
              >
                {language === 'fr' ? t('featureTitle3') : t('featureTitle2')}
              </span>{' '}
              <span className="text-[#a238d5] inline-block relative">
                {t('featureTitle4')}
              </span>{' '}
              !
            </h1>
            <p className="head-description-productly">{t('featureSubtitle')}</p>
          </div>
        </div>
        {/* <SlideProducts
          products={mockProducts}
          isProductsLoading={isProductsLoading}
        /> */}
      </>
    </Animation>
  );
}

export default FeatureSection;
