import { apiSlice } from './apiSlice';

const timeSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTime: build.query<string, void>({
      query: () => ({
        url: `time`,
        method: 'GET',
      }),
    }),
    getSpecialTime: build.query<{ specialtime: boolean }, void>({
      query: () => ({
        url: `time/special`,
        method: 'GET',
      }),
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useGetTimeQuery, useGetSpecialTimeQuery } = timeSlice;
