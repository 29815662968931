/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

function Image({
  className,
  imageSrc,
  zoomIn,
}: {
  className?: string;
  imageSrc: string;
  zoomIn: (src: string) => void;
}) {
  return (
    <img
      className={`w-[200px] box-border cursor-pointer ${className}`}
      alt="Productly"
      src={imageSrc}
      onClick={() => zoomIn(imageSrc)}
    />
  );
}

export default Image;
