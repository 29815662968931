import { apiSlice } from './apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ['ANNOUNCEMENT_DATA'],
});

const announcementSlice = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getAnnouncements: build.query<any, { limit?: number }>({
      query: ({ limit = 3 }) => ({
        url: `announcement?limit=${limit}`,
        method: 'GET',
      }),
    }),
    getAnnouncementByUser: build.query<any, { limit?: number }>({
      query: ({ limit = 1 }) => ({
        url: `announcement/latest?limit=${limit}`,
        method: 'GET',
      }),
      providesTags: ['ANNOUNCEMENT_DATA'],
    }),
    likeAnnouncement: build.mutation<any, { id: number }>({
      query: (payload) => ({
        url: 'announcement/like',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['ANNOUNCEMENT_DATA'],
    }),
    closeAnnouncement: build.mutation<any, { id: number }>({
      query: (payload) => ({
        url: 'announcement/close',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['ANNOUNCEMENT_DATA'],
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useLazyGetAnnouncementsQuery,
  useGetAnnouncementsQuery,
  useGetAnnouncementByUserQuery,
  useLazyGetAnnouncementByUserQuery,
  useLikeAnnouncementMutation,
  useCloseAnnouncementMutation,
} = announcementSlice;
