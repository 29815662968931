import React from 'react';
import { BiSolidLike } from 'react-icons/bi';

import Button from '../common/Button';

import { cn } from '@/lib/utils';

interface LikeButtonProps {
  text: string | any;
  onClick: () => void;
  className?: string;
  liked: boolean;
  disable?: boolean;
}

function AnnouncementLikeButton({
  text,
  onClick,
  className,
  liked,
  disable,
}: LikeButtonProps) {
  return (
    <Button
      icon={
        <BiSolidLike className={cn('text-white', liked && 'text-primary')} />
      }
      iconPosition="before"
      type="submit"
      text={text}
      className={cn(
        'flex items-center justify-center gap-1 bg-zinc-600 text-white h-full border border-solid rounded-lg hover:bg-zinc-800',
        liked && 'bg-zinc-800',
        className
      )}
      onClick={onClick}
      disabled={disable}
    />
  );
}

export default AnnouncementLikeButton;
