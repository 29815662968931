import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import clsx from 'clsx';
import GoogleLoginBtn from 'components/Buttons/GoogleLogin';
import Animation from 'components/common/Animation';
import Button from 'components/common/Button';
import KeyIcon from 'components/common/svg/KeyIcon';
import useCountDown from 'components/hooks/useCountDown';
import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { resendVerifyEmail, URLSearchParamsToObject } from 'utils/helpers';

import { Alert, AlertDescription } from '@/components/common/Alert';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/common/Card';
import { Input } from '@/components/common/Input';
import { Label } from '@/components/common/Label';
import Header from '@/components/Header';
import { setCredentials } from '@/shared/reducers/authReducer';
import { RootState } from '@/shared/rootReducers';
import { usePostLoginMutation } from '@/shared/slices/authSlice';
import { useUpdateShopifyConnectMutation } from '@/shared/slices/shopifySlice';
import { useLazyGetUserSubscriptionQuery } from '@/shared/slices/subscriptionSlice';

interface LoginFormDataProps {
  email: string;
  password: string;
  recaptchaToken: string;
}

function Login() {
  const dispatch = useDispatch();
  const [postLogin, { isLoading }] = usePostLoginMutation();

  const { user } = useSelector((state: RootState) => state?.auth);

  const navigate = useNavigate();
  const [formData, setFormData] = useState<LoginFormDataProps>({
    email: '',
    password: '',
    recaptchaToken: '',
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [inactiveEmail, setInactiveEmail] = useState<boolean>(false);
  const { timeDown: timeToResendEmail, setTimeDown } = useCountDown(0);
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [searchParams, _] = useSearchParams();
  const { search: queryParams, state } = useLocation();
  const queryToObject = URLSearchParamsToObject(queryParams);
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });

  const [getSubscriptionInfo] = useLazyGetUserSubscriptionQuery();
  const [updateShopify] = useUpdateShopifyConnectMutation();

  const shopifyConnectUpdateHanlde = async () => {
    if (!queryToObject?.shopid || !user) return;

    await updateShopify({
      shopid: queryToObject?.shopid,
    }).unwrap();

    navigate('/shopify');
  };

  useEffect(() => {
    // This is for google login callback
    const token = searchParams.get('token');
    const amazonUrl = searchParams.get('amazonUrl');

    if (token && !user) {
      dispatch(setCredentials({ token }));
    }
    if (amazonUrl && user) {
      navigate('/app', { state: { amazonUrl, showModal: true } });
    }
    if (user) {
      shopifyConnectUpdateHanlde();
      navigate(state?.previousPath ?? '/app', {
        state: queryToObject,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleGoogleLogin = () => {
    const amazonUrl = localStorage.getItem('amazonUrl');
    const params = new URLSearchParams({
      ...(amazonUrl && {
        amazonUrl,
      }),
      ...queryToObject,
    }).toString();
    window.location.href = `${process.env.VITE_APP_API_URL}/auth/google?${params}`;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleLogin = async () => {
    setInactiveEmail(false);
    setErrorMessage(null);
    try {
      const { email, password } = formData;
      if (!email || !password) {
        setErrorMessage(t('errors.missingInformation'));
        return;
      }
      const reCaptchaToken = captchaRef.current?.getValue();
      if (!reCaptchaToken) {
        setErrorMessage(t('errors.emptyRecaptcha'));
        return;
      }
      const userLogin = await postLogin({
        ...formData,
        recaptchaToken: captchaRef.current?.getValue() ?? '',
      }).unwrap();

      captchaRef.current?.reset();
      if (userLogin?.code === `incorrect`) {
        setErrorMessage(t('errors.wrongPassword'));
        return;
      }

      if (userLogin?.code === `inactive`) {
        setInactiveEmail(true);
        setErrorMessage(t('errors.errorOccur'));
        return;
      }
      // Store the login infomation
      dispatch(setCredentials(userLogin));
      const amazonUrl = localStorage.getItem('amazonUrl');
      if (amazonUrl) {
        navigate('/app', { state: { amazonUrl, showModal: true } });
      }
      getSubscriptionInfo(undefined, false);
      shopifyConnectUpdateHanlde();

      navigate(state?.previousPath ?? '/app', {
        state: URLSearchParamsToObject(queryParams),
      });
    } catch (error) {
      setErrorMessage(t('errors.errorOccur'));
    }
  };

  const handleResend = async () => {
    const resendResponse = await resendVerifyEmail(formData?.email);
    if (resendResponse === true) {
      setTimeDown(59);
      toast.success(t('resendVerificationEmailSuccess'));
    }
  };

  return (
    <div data-testid="login-display">
      <div className="absolute w-full h-[1050px] pointer-events-none bg-login-gradient-background" />
      <Header />
      <div className="mx-auto w-[414px] py-36">
        <Animation className="bg-transparent">
          <Card className="text-white bg-white border border-white border-solid bg-opacity-10 border-opacity-10">
            <CardHeader>
              <CardTitle className="m-0 text-3xl text-center">
                {t('connection')}
              </CardTitle>
            </CardHeader>
            <CardContent className="p-[36px] py-0">
              <div className="grid w-full max-w-sm items-center gap-1.5">
                <Label htmlFor="email">Email</Label>
                <Input
                  className="w-auto text-white bg-white border-0 bg-opacity-5"
                  placeholder="Enter your email"
                  data-testid="email"
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="grid w-full max-w-sm items-center gap-1.5 mt-6">
                <Label htmlFor="email">Password</Label>
                <Input
                  className="w-auto text-white bg-white border-0 bg-opacity-5"
                  placeholder="Enter your password"
                  id="password"
                  data-testid="password"
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>
              <div className="mt-8 mb-4 ml-5 transform scale-112">
                <ReCAPTCHA
                  theme="dark"
                  ref={captchaRef}
                  sitekey={process.env.VITE_APP_GOOGLE_RECAPTCHA_SITE_KEY!}
                />
              </div>
              {errorMessage && (
                <div data-testid="error-message">
                  {inactiveEmail ? (
                    <>
                      <Alert variant="destructive">
                        <ExclamationTriangleIcon className="w-4 h-4" />
                        <AlertDescription>{`${t('errors.emailInactived')}. ${t('inactiveEmailInstruction')}`}</AlertDescription>
                      </Alert>
                      <Alert className="mt-2" variant="warning">
                        <AlertDescription>
                          {timeToResendEmail > 0
                            ? `${t('tryAgainAfter')}: ${timeToResendEmail}s`
                            : `${t('resendVerificationEmailInstruction')}:`}
                          <Button
                            type="button"
                            text={
                              timeToResendEmail > 0
                                ? `${t('resendVerificationEmailSuccess')}`
                                : `${t('resendEmail')}`
                            }
                            className={clsx(
                              'w-full p-4 outline-none border-0 bg-white text-black font-bold rounded-md mt-2',
                              timeToResendEmail > 0
                                ? `submit-button !mt-2 !px-1 !bg-neutral-700 !text-white`
                                : 'submit-button !mt-2 !px-1 hover:!bg-neutral-700 hover:!text-white'
                            )}
                            onClick={handleResend}
                            disabled={timeToResendEmail > 0}
                          />
                        </AlertDescription>
                      </Alert>
                    </>
                  ) : (
                    <Alert variant="destructive">
                      <ExclamationTriangleIcon className="w-4 h-4" />
                      <AlertDescription>{errorMessage}</AlertDescription>
                    </Alert>
                  )}
                </div>
              )}

              <Button
                type="button"
                data-testid="login-btn"
                text={t('loginBtn')}
                className="w-full p-4 mt-2 font-bold text-black bg-white border-0 rounded-md"
                onClick={handleLogin}
                loading={isLoading}
                loadingText="En cours de connexion..."
              />
              <Button
                type="button"
                className="w-full p-4 mt-2 font-bold text-black bg-white border-0 rounded-md"
                text={t('forgotPasswordBtn')}
                icon={<KeyIcon />}
                onClick={() => navigate('/forgot')}
              />
              <GoogleLoginBtn
                className="w-full border-0 outline-none"
                onClick={handleGoogleLogin}
                name={t('googleSignIn')}
              />
            </CardContent>
            <CardFooter className="justify-center py-10">
              <Link to="/register" className="text-white register-button-log">
                {t('signUpBtn')}
              </Link>
            </CardFooter>
          </Card>
        </Animation>
      </div>
    </div>
  );
}

export default Login;
