import React from 'react';
import { useTranslation } from 'react-i18next';

function PromoCodeBadge() {
  const { t } = useTranslation('translation');
  return (
    <div className="promo-box text-center rounded-lg p-3 mb-4 text-xs border-solid bg-transparent/25 text-white mt-3">
      <div className="new-badge absolute top-[122px] left-[40px] bg-yellow-300 text-black font-bold text-xs py-1 px-3 rotate-[-45deg] origin-top-left w-[50px]">
        {t('upgrade.special')}
      </div>
      <p className="font-semibold">{t('upgrade.promoCode')}</p>
      <p className="font-bold text-yellow-300 border-solid border-[1px] border-white p-2">
        ECOMCHALLENGE
      </p>
      <p className="font-bold">{t('upgrade.discount20')}</p>
      <p className="italic font-bold">{t('upgrade.trial7days')}</p>
    </div>
  );
}

export default PromoCodeBadge;
