/* eslint-disable no-nested-ternary */
import { format, fromUnixTime } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '../common/Button';
import TextLoadingSkeleton from '../common/TextLoadingSkeleton';
import ConfirmationModal from '../modals/ConfirmationModal';

import { displayFeature, FeatureFlags } from '@/shared/configs/featureFlags';
import { currencySign } from '@/shared/currency';
import { RootState } from '@/shared/rootReducers';
import { useGetUserSubscriptionQuery } from '@/shared/slices/subscriptionSlice';

interface facturationDataProps {
  daysUntilRenewal: number;
  deleteSubscription: () => void;
}

function BillingInfo({
  daysUntilRenewal,
  deleteSubscription,
}: facturationDataProps) {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const { currency } = useSelector((state: RootState) => state?.plan);
  const [cancelConfirmModal, setCancelConfirmModal] = useState(false);
  const { subscriptionCanceled } = useSelector(
    (state: RootState) => state?.accountArea
  );
  const { data: subscriptionInfo, isFetching: subscriptionLoading } =
    useGetUserSubscriptionQuery();
  const currencySymbol = currency ? currencySign?.[currency] : '$';
  const {
    plan,
    renewalDate,
    price,
    isAnnual,
    card,
    status,
    offerAccept,
    upcoming,
  } = subscriptionInfo ?? {};
  const isLifetimePlan = plan === 'Lifetime';
  const annualText = isAnnual ? t('account.an') : t('account.mois');
  const pricePerPeriod = price ? (price / 100).toFixed(2) : 0;
  const priceText = `${currencySymbol}${pricePerPeriod} / ${annualText}`;

  const cancelSubscriptionWizard = displayFeature({
    featureFlag: FeatureFlags?.PRODLY_453_CANCEL_SUBSCRIPTION_WIZARD_20242110,
  });

  return (
    <div className="flex flex-col">
      {/* Title */}
      <div className="relative pb-1 text-white">
        <h1 className="after:content-[''] after:absolute after:bottom-0 after:left-[-15px] after:bg-gradient-to-r after:right-0 after:h-[1px] after:from-transparent after:from-[0%] after:via-[rgba(255,255,255,0.2)] after:via-[20%] after:to-transparent after:to-[100%]">
          {t('account.billingHeader')}
        </h1>
      </div>
      {subscriptionLoading ? (
        <TextLoadingSkeleton />
      ) : (
        <>
          {/* Current plan */}
          <div className="mt-5 font-semibold">
            <h3 className="text-[16px]">{t('account.planEnCours')}</h3>
          </div>
          {/* Card with Pyament Informatin */}
          <div className="pb-7 flex flex-col px-4 py-3 font-semibold rounded-lg border-solid border-[rgba(255,255,255,0.15)] bg-gradient-to-r from-[#8628C0] to-[#A238D5]">
            <span className="text-[12px] mb-4">Productly {plan ?? 'Free'}</span>
            {!isLifetimePlan && (
              <span className="text-[20px] font-bold mb-4">{priceText}</span>
            )}
            {status && (
              <span className="text-[10px] mb-2">
                {isLifetimePlan && t('account.neverExpire')}
                {!isLifetimePlan && (
                  <span>
                    {subscriptionCanceled
                      ? `${t('account.subscriptionCanceledTrue')}: ${format(fromUnixTime(renewalDate), 'dd/MM/yyyy')}`
                      : `${t('account.subscriptionCanceledFalse')} ${daysUntilRenewal} ${t('account.jours')}*`}
                  </span>
                )}
              </span>
            )}
            {!subscriptionCanceled && (
              <div>
                {card ? (
                  <span className="text-[9px] font-normal">
                    {t('account.subscriptionInfo1')}{' '}
                    {subscriptionInfo.card.last4}
                  </span>
                ) : (
                  <span className="text-[9px] font-normal">
                    {t('account.subscriptionInfo2')}
                  </span>
                )}
              </div>
            )}
          </div>
          {/* Upcoming */}
          {upcoming && (
            <>
              <div className="mt-5 font-semibold">
                <h3 className="text-[16px]">
                  {t('common.upcoming')}{' '}
                  <span className="bg-[#63a03d] p-2 py-0 rounded-sm text-sm">
                    {offerAccept ?? 0}% {t('common.off')}
                  </span>
                </h3>
              </div>
              <div className="flex flex-col px-4 py-3 font-semibold rounded-lg border-solid border-[rgba(255,255,255,0.15)] bg-gradient-to-r from-[#63a03d] to-[#6db34c]">
                <div className="text-sm line-through opacity-70 font-normal">
                  {priceText}
                </div>
                <div className="text-[20px] font-bold mb-2">
                  {currencySymbol}
                  {Math.trunc(
                    (price - (price * (offerAccept ?? 0)) / 100) / 100
                  ).toFixed(2)}{' '}
                  / {t('account.mois')}
                </div>
                <span className="text-[12px]">
                  {t('common.subscription')} {plan ?? 'Free'}{' '}
                  {t('common.startingFrom')}{' '}
                  {format(fromUnixTime(upcoming), 'dd/MM/yyyy')}
                </span>
              </div>
            </>
          )}
          {/* Subscriptions buttons */}
          {!isLifetimePlan && (
            <>
              <div className="mt-5">
                <Button
                  type="button"
                  className="w-full p-3 mt-2 font-bold text-black bg-white border-0 rounded-md"
                  text={t('account.chargerDePlan')}
                  onClick={() => navigate('/upgrade')}
                />
              </div>
              {!subscriptionCanceled && plan !== 'Free' && (
                <Button
                  type="button"
                  className="w-full p-3 mt-5 font-bold text-white bg-black border-[1px] rounded-md"
                  onClick={() => {
                    // if (cancelSubscriptionWizard && offerAccept === 0) {
                    //   navigate('/subscription/cancel');
                    //   return;
                    // }
                    setCancelConfirmModal(true);
                  }}
                  text={t('account.subscriptionCanceled')}
                />
              )}
            </>
          )}
          <ConfirmationModal
            open={cancelConfirmModal}
            title={t('account.cancelConfirmTitle')}
            onConfirm={() => {
              deleteSubscription();
              setCancelConfirmModal(false);
            }}
            onDecline={() => setCancelConfirmModal(false)}
            declineFocus
          >
            <span className="text-sm">
              {offerAccept > 0
                ? t('account.cancelConfirmContentDiscount', {
                    discount: offerAccept,
                  })
                : t('account.cancelConfirmContent', {
                    expiredDate: format(
                      fromUnixTime(renewalDate),
                      'dd/MM/yyyy'
                    ),
                  })}
            </span>
          </ConfirmationModal>
        </>
      )}
    </div>
  );
}

export default BillingInfo;
