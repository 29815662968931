/* eslint-disable react/no-danger */
import size from 'lodash/size';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

import AnnouncementLikeButton from './Buttons/AnnouncementLikeButton';
import Button from './common/Button';
import Skeleton from './common/Skeleton';

import { cn } from '@/lib/utils';
import {
  useCloseAnnouncementMutation,
  useGetAnnouncementByUserQuery,
  useLikeAnnouncementMutation,
} from '@/shared/slices/announcementSlice';

function Announcement() {
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { data: latestData, isFetching: announcementFetching } =
    useGetAnnouncementByUserQuery({ limit: 1 });
  const [likeAnnouncement, { isLoading: handleLikeLoading }] =
    useLikeAnnouncementMutation();
  const [closeAnnouncement, { isLoading: isCloseLoading }] =
    useCloseAnnouncementMutation();
  const [likeButtonRunning, setLikeButtonRunning] = useState<boolean>(false);
  const [lastestAnnouncement] = latestData?.data ?? [];
  const {
    likeCount,
    announcementLiked,
    id: announcementId,
    open: openAnnouncement,
  } = lastestAnnouncement ?? {};

  const handleLike = async () => {
    if (announcementLiked) return;
    setLikeButtonRunning(true);
    try {
      const response: Record<string, any> = await likeAnnouncement({
        id: announcementId,
      });
      if (response.success) {
        setLikeButtonRunning(false);
      }
    } catch (error) {
      setLikeButtonRunning(false);
    }
  };

  const handleClose = async () => {
    await closeAnnouncement({
      id: announcementId,
    });
  };

  if (!size(lastestAnnouncement)) return null;

  return (
    <div
      className={cn(
        `"w-full h-20 flex justify-center items-center mt-16 mb-24 sm:my-4 sm:mb-12 2xl:mt-6"`,
        !openAnnouncement && 'hidden'
      )}
    >
      {(announcementFetching || isCloseLoading) && !likeButtonRunning ? (
        <Skeleton className="w-[800px] h-16" />
      ) : (
        <div className="bg-black text-white w-[800px] flex flex-col p-3 rounded-2xl border-none">
          <div>
            <div className="flex justify-between">
              <div className="text-start text-sm font-bold ms-5 mb-2">
                {t('common.lastedUpdate')}
              </div>
              <Button
                text=""
                className="bg-transparent border-0 outline-none"
                icon={<IoMdClose className="text-2xl text-white" />}
                onClick={handleClose}
              />
            </div>
            <div
              className="text-xs ms-5"
              dangerouslySetInnerHTML={{
                __html:
                  language === 'fr'
                    ? lastestAnnouncement.content_fr
                    : lastestAnnouncement.content_en,
              }}
            />
          </div>
          <div className="flex space-x-2 justify-end h-8">
            <Button
              onClick={() => {
                navigate('/latest-release');
              }}
              className="border-none bg-zinc-600 rounded-sm px-3 py-1 text-sm text-white hover:bg-zinc-800"
            >
              {t('common.readMore')}
            </Button>
            <AnnouncementLikeButton
              text={likeCount}
              onClick={handleLike}
              liked={!!lastestAnnouncement?.announcementLiked}
              className="!h-full text-sm border-none rounded-sm"
              disable={
                !!lastestAnnouncement?.announcementLiked ||
                handleLikeLoading ||
                announcementFetching
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Announcement;
