/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/prefer-default-export */
import { AccordionMultipleProps } from '@radix-ui/react-accordion';
import { camelCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAutorenew } from 'react-icons/md';
import { PiCopyLight } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import TextLoadingSkeleton from '../common/TextLoadingSkeleton';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/common/Accordion';
import Button from '@/components/common/Button';
import { setProductsState } from '@/shared/reducers/productsReducer';
import { RootState } from '@/shared/rootReducers';
import { useLazyGetServiceDetailQuery } from '@/shared/slices/productSlice';
import { useRegenerateContentMutation } from '@/shared/slices/regenerateContent';
import cn from '@/utils/cn';
import formatLineBreaks from '@/utils/format';

interface ServiceContentProps {
  type?: AccordionMultipleProps['type'];
  children: React.ReactElement[];
  defaultValue?: string[];
  className?: string;
}

interface ServiceContentItemProps {
  partTitle: string;
  content: string;
  generateContentId: string;
  isHide?: boolean;
  isDisableRegenerateButtom?: boolean;
  titleRegenerate?: string;
}

export function ServiceContent({
  type = 'multiple',
  children,
  defaultValue = [],
  className = '',
}: ServiceContentProps) {
  const [valueState, setValueState] = useState<string[]>([]);

  useEffect(() => {
    setValueState(defaultValue);
  }, [defaultValue]);

  return (
    <Accordion
      type={type}
      value={valueState}
      onValueChange={(value: string[]) => setValueState(value)}
      className={cn('w-full', className)}
    >
      {children}
    </Accordion>
  );
}

function HtmlContent({ html }: { html: string }) {
  return (
    <p
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
}

export function ServiceContentItem({
  partTitle,
  content,
  isHide = false,
  generateContentId,
  isDisableRegenerateButtom = false,
  titleRegenerate,
}: ServiceContentItemProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const title = t(`product.${camelCase(partTitle)}`);
  // titleForRegenerate is used only for syncing with the BE data (title key only in French)
  const titleForRegenerate = t(`product.${camelCase(partTitle)}`, {
    lng: 'fr',
  });

  const { currentProductId, selectedService } = useSelector(
    (state: RootState) => state?.products
  );

  const [getRegenerateContent] = useRegenerateContentMutation();
  const [getServiceDetail] = useLazyGetServiceDetailQuery();

  const copyToClipBoard = (value: string) => {
    if (!value || value?.length <= 0) return;
    navigator.clipboard.writeText(value);
    toast.success(t('common.copiedSuccess'));
  };

  const regenerateContent = async (titleKey: string) => {
    if (!currentProductId) return;
    try {
      const response = await getRegenerateContent({
        productId: currentProductId,
        title: titleKey,
        generateContentId,
        selectedService,
      }).unwrap();
      if (response) {
        await getServiceDetail(
          {
            productId: currentProductId,
            service: selectedService ?? null,
          },
          false
        );
      }
      dispatch(setProductsState({ titleRegenerate: '' }));
      dispatch(setProductsState({ productsRegenerate: false }));
    } catch (error) {
      dispatch(setProductsState({ productsRegenerate: false }));
    }
  };

  let contentShow;
  let contentHide;
  if (isHide) {
    const contentPartitionLength = 8;
    const firstPart = Math.floor(content.length / contentPartitionLength);
    contentShow = formatLineBreaks(content?.slice(0, firstPart));
    contentHide = formatLineBreaks(content?.slice(contentPartitionLength));
  }
  return (
    <AccordionItem value={title}>
      <AccordionTrigger className="bg-[#D9D9D9] bg-opacity-10 data-[state=open]:border-primary">
        {title}
      </AccordionTrigger>
      <AccordionContent className="mt-3">
        {!isHide && (
          <>
            {isDisableRegenerateButtom && titleRegenerate === title ? (
              <TextLoadingSkeleton />
            ) : (
              <HtmlContent html={formatLineBreaks(content)} />
            )}

            <div className="mt-2 flex">
              <Button
                className="text-2xl font-bold text-gray-300 hover:text-primary bg-transparent outline-none border-0"
                text=""
                icon={<PiCopyLight />}
                iconPosition="before"
                onClick={() => copyToClipBoard(content)}
              />
              <Button
                className="text-2xl font-bold text-gray-300 hover:text-primary bg-transparent outline-none border-0"
                text=""
                icon={<MdAutorenew />}
                iconPosition="before"
                onClick={() => {
                  dispatch(setProductsState({ productsRegenerate: true }));
                  dispatch(setProductsState({ titleRegenerate: title }));
                  regenerateContent(titleForRegenerate);
                }}
                disabled={isDisableRegenerateButtom}
              />
            </div>
          </>
        )}
        {isHide && (
          <div className="relative">
            <div className="clear">
              <HtmlContent html={contentShow} />
            </div>

            <div className=" blur-[8px] p-5">
              <HtmlContent html={contentHide} />
            </div>
            <Button
              className="border border-solid border-[#ffffff4d] rounded-sm uppercase outline-none py-3 px-4 absolute top-1/2 left-[40%] bg-white text-white bg-opacity-5"
              text="Mise à niveau"
              onClick={() => navigate('/upgrade')}
            />
          </div>
        )}
      </AccordionContent>
    </AccordionItem>
  );
}
