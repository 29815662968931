/* eslint-disable jsx-a11y/role-has-required-aria-props */
import clsx from 'clsx';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleDown } from 'react-icons/fa6';
import { MdMiscellaneousServices } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import ConnectToShopifyButton from '@/components/Buttons/ConnectToShopifyButton';
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from '@/components/common/Command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/common/Popover';
import { setApplicationState } from '@/shared/reducers/applicationReducer';
import { setFromScratch } from '@/shared/reducers/fromScratchReducer';
import { setProductsState } from '@/shared/reducers/productsReducer';
import { RootState } from '@/shared/reduxStore';
import { useLazyGetServiceDetailQuery } from '@/shared/slices/productSlice';
import { useGetShopifyTemplateDataQuery } from '@/shared/slices/shopifySlice';
import { Service } from '@/shared/types/service';

interface ServicesMenuMobileProps {
  services: Service[];
  productDetail: any;
  reviews: any[] | null;
}

function ServicesMenuMobile({
  services,
  productDetail,
  reviews,
}: ServicesMenuMobileProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const { currentProductId, selectedService, products, allErrorProducts } =
    useSelector((state: RootState) => state?.products);
  const { user } = useSelector((state: RootState) => state?.auth);
  const [open, setOpen] = React.useState(false);
  const [getServiceDetail] = useLazyGetServiceDetailQuery();
  // When shopify template loading for the first time, need to freeze the Homepage menu too
  const { isFetching: shopifyDataLoading } = useGetShopifyTemplateDataQuery(
    {
      productId: currentProductId,
      homepage: 0,
    },
    { skip: !currentProductId }
  );
  const selectedServiceMobile = services.filter(
    (service) => service.key === selectedService
  );
  const [selectedServiceMobileDetail] = selectedServiceMobile;
  useEffect(() => {
    if (!selectedService || selectedService === 'reviews') return;
    getServiceDetail({
      productId: currentProductId,
      service: selectedService,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProductId, selectedService]);

  const isUserPlanStillValid = user?.expiration !== true;

  return (
    <div className="flex lg:hidden mt-6 items-center justify-between w-[90%]">
      <div className="block">
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <button
              type="button"
              role="combobox"
              aria-expanded={open}
              className="flex items-center w-[200px] md:w-[220px] justify-between px-3 py-1.5 text-xs font-semibold text-white bg-zinc-900 border-primary border-solid rounded-md border"
            >
              <div className="flex items-center">
                <span className="text-lg material-symbols-outlined services-icons me-2">
                  {selectedServiceMobileDetail?.icon || (
                    <MdMiscellaneousServices />
                  )}
                </span>
                <span>
                  {selectedServiceMobileDetail?.key
                    ? t(
                        `memberArea.serviceMenus.${selectedServiceMobileDetail?.key}`
                      )
                    : `Menu des services`}
                </span>
              </div>
              <FaAngleDown className="w-4 h-4 ml-2 shrink-0 opacity-70 fill-primary" />
            </button>
          </PopoverTrigger>
          <PopoverContent className="w-[220px] !p-0 ">
            <Command>
              <CommandList className="bg-zinc-900">
                <CommandGroup>
                  {services.map((service, index) => {
                    const isServiceLocked =
                      service.locked || !isUserPlanStillValid;
                    const isContentFilled = () => {
                      if (!selectedService) return true;
                      if (service.locked) return true;
                      if (service.key === 'homepage') {
                        return shopifyDataLoading === false;
                      }
                      if (service.key === 'reviews') {
                        return !!reviews;
                      }
                      if (service.key === 'ecomApplication') {
                        return true;
                      }
                      if (service.api_key && productDetail?.[service.api_key]) {
                        return true;
                      }
                      return false;
                    };
                    const isServicesShow = () => {
                      if (service.key === 'reviews') {
                        return true;
                      }
                      if (service.key === 'ecomApplication') {
                        return true;
                      }
                      if (productDetail?.generated) {
                        return productDetail?.[service?.api_key ?? ''];
                      }
                      return true;
                    };
                    const disableService =
                      !isContentFilled() ||
                      isServiceLocked ||
                      allErrorProducts ||
                      (products && products.length <= 0);
                    return (
                      <CommandItem
                        key={service.key}
                        value={service.key}
                        tabIndex={index}
                        disabled={disableService}
                        className={clsx(
                          isServiceLocked && ' locked',
                          selectedService === service.key &&
                            'selected' &&
                            'bg-zinc-800',
                          !isServicesShow() && '!hidden',
                          disableService && 'locked opacity-70',
                          !productDetail && 'pointer-events-none opacity-70'
                        )}
                        onSelect={() => {
                          setOpen(false);
                          dispatch(setFromScratch({ active: false }));
                          dispatch(setApplicationState({ active: false }));
                          dispatch(
                            setProductsState({
                              shopifyImport: false,
                            })
                          );
                          switch (service.key) {
                            case 'ecomApplication':
                              dispatch(setApplicationState({ active: true }));
                              dispatch(
                                setProductsState({
                                  selectedService: 'ecomApplication',
                                })
                              );
                              dispatch(
                                setProductsState({
                                  shopifyImport: false,
                                  shopifyHomepageImportTemplate: false,
                                })
                              );
                              break;
                            case 'description':
                              dispatch(
                                setProductsState({
                                  shopifyImport: true,
                                  shopifyHomepageImportTemplate: false,
                                })
                              );
                              break;
                            case 'homepage':
                              dispatch(
                                setProductsState({
                                  shopifyImport: false,
                                  shopifyHomepageImportTemplate: true,
                                })
                              );
                              break;
                            default:
                              dispatch(
                                setProductsState({
                                  shopifyImport: false,
                                  shopifyHomepageImportTemplate: false,
                                })
                              );
                          }
                          if (
                            productDetail &&
                            !service.locked &&
                            isContentFilled()
                          ) {
                            dispatch(
                              setProductsState({ selectedService: service.key })
                            );
                          }
                          if (service.key === 'ecomApplication') {
                            dispatch(setApplicationState({ active: true }));
                          }
                        }}
                      >
                        <div className="inline-flex items-center ms-2">
                          <span className="text-xl text-white me-2 material-symbols-outlined">
                            {service.icon}
                          </span>
                          <h4 className="text-xs text-white">
                            {t(`memberArea.serviceMenus.${service.key}`)}
                          </h4>
                          {isServiceLocked && (
                            <span className="lock-icon">🔒</span>
                          )}
                        </div>
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
      <ConnectToShopifyButton text={t('shopify.connectToShopify')} />
    </div>
  );
}
export default ServicesMenuMobile;
